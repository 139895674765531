<template>
  <div class="terminos_container">
    <div class="content">
      <h1 class="common_title">Preguntas frecuentes</h1>
      <div class="inner_01">
        <div class="inner">
          <h2 class="title">Empresas</h2>
          <ul>
            <li v-for="(item, index) in textListOne" :key="item.title">
              <i :class="text01Active === index ? 'el-icon-arrow-down':'el-icon-arrow-up'" @click="handleShowTextActive(index, 'text01Active')"></i>
              <div :class="text01Active === index ? 'maxShow':'maxHidden'">
                <h3 class="inner_title common_content_bold">{{ item.title }}</h3>
                <div v-html="item.content" class="inner_content" :class="text01Active === index ? 'maxShow':'maxHidden'"></div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="inner_02">
        <div class="inner">
          <h2 class="title">Colaboradores</h2>
          <ul>
            <li v-for="(item, index) in textListTwo" :key="item.title">
              <i :class="text02Active === index ? 'el-icon-arrow-down':'el-icon-arrow-up'" @click="handleShowTextActive(index, 'text02Active')"></i>
              <div :class="text02Active === index ? 'maxShow':'maxHidden'">
                <h3 class="inner_title common_content_bold">{{ item.title }}</h3>
                <div v-html="item.content" class="inner_content" :class="text02Active === index ? 'maxShow':'maxHidden'"></div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="inner_03">
        <h1 class="common_title">¿Aún tienes dudas?</h1>
        <p class="common_content p_content">Haz click aquí para contactar a un asesor</p>
        <p class="common_content p_btn"><a href="https://www.facebook.com/messages/t/105692188404229" target="_blank" rel="noopener noreferrer">Hablar con un asesor</a></p>
      </div>
    </div>
  </div>
</template>

<script>
import { textListOne, textListTwo } from './index.config'
// import { arrToggle } from '@/utils'
export default {
  data () {
    return {
      textListOne,
      textListTwo,
      text01Active: 0,
      text02Active: 0,
      // showTextList: [0]
    }
  },
  methods: {
    // showTextListHandle(index) {
    //   const { arr } = arrToggle(index, this.showTextList)
    //   this.showTextList = [...arr]
    // },
    handleShowTextActive(index, type='text01Active') {
      this[type] = this[type] === index ? null : index
    }
  },
}
</script>

<style scoped lang='scss'>
.terminos_container {
  width: 100%;
  .content {
    padding-top: 60px;
    margin: 0 auto;
    color: var(--primary-blue);
    h1 {
      width: 86%;
      margin: 0 auto;
      color: var(--primary-blue);
    }
    .inner_01, .inner_02 {
      padding: 60px 0;
      width: 100%;
      .inner {
        width: 86%;
        margin: 0 auto;
        .title {
          font-size: 35px;
          font-weight: bold;
          color: var(--primary-red);
          margin-top: 0;
        }
        ul {
          width: 70%;
          li {
            display: flex;
            &:not(:last-child) {
              margin: 50px 0;
            }
            i {
              font-size: 25px;
              margin-right: 30px;
              cursor: pointer;
              color: var(--primary-red);
            }
            div.maxShow {
              max-height: 250px;
            }
            div.maxHidden {
              max-height: 30px;
            }
            div {
              overflow: hidden;
              transition: 0.5s max-height;
              h3 {
                height: 30px;
                color: var(--primary-blue);
                font-size: 20px;
                font-weight: bold;
              }
              ::v-deep .inner_content {
                margin-top: 10px;
                p {
                  word-break: break-word;
                  font-size: 20px;
                  line-height: 40px;
                  padding: 0;
                }
                ul {
                  li {
                    margin: 10px 0;
                    font-size: 20px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .inner_02 {
      background-color: #e3f1ff;
    }
    .inner_03 {
      padding: 80px 0;
      background-color: var(--primary-red);
      text-align: center;
      h1 {
        color: #fff;
      }
      p {
        color: #fff;
        font-size: 25px;
      }
      .p_content {
        margin: 20px 0;
      }
      .p_btn {
        background-color: var(--primary-blue);
        display: inline-block;
        border-radius: 50px;
        padding: 0;
        width: 350px;
        height: 70px;
        line-height: 70px;
        a {
          color: #fff;
        }
      }
    }
  }
}
@media screen and (max-width: 1246px) {
  .terminos_container {
    .content {
      padding-top: 120px;
      h1 {
        width: 86%;
        font-size: 65px !important;
      }
      .inner_01, .inner_02 {
        .inner {
          .title {
            font-size: 55px !important;
          }
          ul {
            width: 100%;
            li {
              i {
                font-size: 40px;
              }
              div.maxShow {
                max-height: 550px;
              }
              div.maxHidden {
                max-height: 85px;
              }
              div {
                h3 {
                  font-size: 30px;
                  height: 85px;
                }
              ::v-deep .inner_content {
                margin-top: 10px;
                p {
                  font-size: 30px;
                  line-height: 40px;
                  margin-top: 0px;
                }
                ul {
                  li {
                    margin: 20px 0;
                    font-size:30px;
                  }
                }
              }
              }
            }
          }
        }
      }
    }
  }
}
</style>
