export const textListOne = [
	{
		title: '¿Qué es TiFi?',
		content:
			'<p>¡TiFi es el mejor aliado financiero de las empresas! A través de su servicio digital de anticipos de nómina, los trabajadores afiliados podrán disfrutar el beneficio de acceder a un porcentaje de su salario trabajado cuando lo requieran, sin tener que esperar a la quincena.</p>'
	},
	{
		title: '¿Cuál es la diferencia entre TiFi y un préstamo?',
		content:
			'<p>A diferencia de un crédito, TiFi busca ayudar a los empleados a resolver contratiempos de manera rápida, segura y sin deuda; pues al solicitar un adelanto de su nómina, no están accediendo a dinero ajeno que puede generar interés, comisiones extras, u otro tipo de pagos que implique una deuda al trabajador. </p>'
	},
	{
		title: '¿Cuáles son los beneficios para las empresas afiliadas y sus colaboradores?',
		content: `
    <ul>
      <li>• Ofrecer una prestación extra sin costo para la empresa</li>
      <li>• Cuidar la salud financiera de los trabajadores</li>
      <li>• Aumentar la atracción y retención de talento</li>
      <li>• Incrementar la productividad del personal</li>
      <li>• No implica cambios en el proceso de nómina de la empresa</li>
    </ul>
    `
	},
	{
		title: '¿Cuánto cuesta la afiliación a TiFi?',
		content:
			'<p>El servicio de acceso al salario diario es completamente gratis para la empresa. TiFi solamente cobra a los empleados una cuota fija por retiro de $39 pesos, sin importar el monto solicitado.</p>'
	},
	{
		title: '¿Quién puede suscribirse?',
		content:
			'<p>Todas las empresas, sin importar la industria, que deseen ofrecer bienestar financiero a sus colaboradores. Al llenar el formulario con sus datos, nosotros nos contactaremos a la brevedad para platicarles con mayor detalle los beneficios de este servicio.</p>'
	},
	{
		title: '¿Cómo funciona?',
		content: `
    <ul>
      <li>1. Afiliación de empresa al servicio TiFi</li>
      <li>2. Solicitud de adelanto por medio de la App por el usuario</li>
      <li>3. Depósito por parte de TiFi del monto en la cuenta de nómina del empleado</li>
      <li>4. Envío de montos a descontar a la empresa</li>
      <li>5. Pago del adelanto por descuento o domiciliación a TiFi</li>
    </ul>
    `
	},
	{
		title: '¿Cuál es el tiempo de implementación en una empresa?',
		content:
			'<p>Una vez que se firma la afiliación entre TiFi y la empresa, integramos la información en nuestro sistema y de inmediato los trabajadores pueden disfrutar del beneficio.</p>'
	},
	{
		title: '¿Los datos están protegidos? ',
		content:
			'<p>Los datos personales que recabamos de cada trabajador y usuario en nuestra plataforma, son utilizados únicamente por nuestra empresa y están resguardados con los más altos controles de calidad. Consulta aquí nuestro Aviso de Privacidad </p>'
	},
	{
		title: '¿Qué pasa cuando un empleado se va de la empresa? ',
		content:
			'<p>El servicio de TiFi quedará inactivo para ese empleado de forma inmediata. Únicamente necesitamos que el empleador informe a su ejecutivo de TiFi para cancelar el acceso a la plataforma. </p>'
	},
	{
		title: '¿Qué sucede si ya no requiero el servicio en algún momento? ',
		content: '<p>El contrato no tiene una vigencia o periodo, en cualquier momento podemos cesar operaciones.</p>'
	},
	{
		title: '¿Hay alguna implicación fiscal o contable para la empresa por contratar este servicio? ',
		content:
			'<p>Ninguna, el concepto lo manejas como adelanto - otras deducciones y pasa como deducción en el timbrado. No genera ningún movimiento extra en los asientos contables. </p>'
	},
	{
		title: '¿Expiden alguna factura?',
		content:
			'<p>No, no podemos expedir factura a la empresa ya que no estamos generando ninguna transacción comercial. Sin embargo, podemos facturar a los empleados que lo requieran. </p>'
	}
]

export const textListTwo = [
	{
		title: '¿Qué es TiFi?',
		content:
			'<p>TiFi es una App que te permite tener acceso a un porcentaje de tu salario trabajado cuando lo necesites. ¡Con TiFi ya no tienes que esperar el día de pago, tu quincena está disponible desde antes!</p>'
	},
	{
		title: '¿Quién puede solicitar un adelanto?',
		content:
			'<p>Podrán hacer uso de nuestro servicio de anticipo de nómina todos aquellos colaboradores cuya empresa esté afiliada a TiFi. Si tu empresa aún no forma parte de éste beneficio, déjanos los datos de tu organización y nosotros nos comunicamos con la compañía.</p>'
	},
	{
		title: '¿Cuánto cuesta ?',
		content: '<p>No importa cuál sea el monto solicitado, con TiFi solo pagarás $39 pesos por retiro. </p>'
	},
	{
		title: '¿Qué se necesita para poder solicitar un adelanto de nómina? ',
		content:
			'<p>Una vez que la empresa está afiliada a TiFi, cada trabajador deberá descargar nuestra aplicación,  registrarse y ¡listo! podrá solicitar su adelanto de nómina.</p>'
	},
	{
		title: '¿Cuáles son los requisitos para registrarse en la App TiFi?',
		content: `
    <ul>
      <li>• CURP</li>
      <li>• RFC</li>
      <li>• Identificación oficial</li>
      <li>• Comprobante de nómina</li>
      <li>• Cuenta bancaria de nómina</li>
    </ul>
    `
	},
	{
		title: '¿Cuál es el horario para realizar una solicitud? ',
		content:
			'<p>TiFi es un servicio on demand, es decir que puedes solicitar tu adelanto de nómina en la hora que lo requieras, ya que se encuentra disponible las 24 horas del día, los 7 días de la semana. </p>'
	},
	{
		title: '¿Cómo se lleva a cabo el depósito del anticipo de nómina? ',
		content:
			'<p>Una vez hecha la solicitud, en cuestión de minutos el depósito con el monto solicitado estará en la cuenta en la que recibes tu quincena. Todas las cuentas bancarias a la cuales se realizan los depósitos deben estar a nombre del trabajador solicitante. </p>'
	},
	{
		title: '¿Cuántas veces se puede solicitar un adelanto en una quincena?',
		content:
			'<p>Puedes hacer uso de la aplicación TiFi las veces que lo necesites, pero recuerda que el monto a solicitar debe ser conforme a los días trabajados y que cada transacción tiene un costo de $39 pesos.</p>'
	},
	{
		title: '¿Cuánto se puede retirar?',
		content:
			'<p>A fin de cuidar el bienestar financiero de los trabajadores, TiFi junto con el empleador, establecen un tope de retiro diario; es decir, que el importe disponible para retirar es un porcentaje del salario acumulado de los días trabajados. </p>'
	},
	{
		title: '¿Puede pedir un adelanto un extranjero?',
		content:
			'<p>¡Claro! TiFi es una compañía inclusiva, por tanto, los adelantos de nómina están diseñados para todos aquellos que mantengan una relación laboral mayor a 90 días, en caso de ser extranjero es importante que además cuentes con tu FM2 como medio de identificación oficial. </p>'
	}
]
